<template>
  <div style="overflow: scroll">
    <Top></Top>
    <!-- 操作栏 -->
    <div class="wallet">
      <div class="top_wallet">
        <div class="top_left">
          <div class="pictrue"><img :src="userinfo.avatar" alt="" /></div>
        </div>
        <div class="top_right">
          <div class="info_name">{{ userinfo.nickname }}</div>
          <div class="info_id">UID: {{ userinfo.userId }}</div>
        </div>
      </div>
      <div class="main_wallet">
        <div class="main_left">
          <span>{{ lang.v.unit }}</span>
          <div ref="walletBox" class="wallet_balance">
            <span ref="walletText">{{ userinfo.balance }}</span>
          </div>
        </div>
        <div class="main_right">
          <!-- <div
            v-if="member"
            class="transfer"
            @click="show_transfer"
            :style="{
              background: userinfo.extractSingleMax == 0 ? 'gray' : '#ED931E',
            }"
          >
            {{ lang.v.Transfer }}
          </div> -->
          <div
            class="withdrawal"
            @click="show_tixian"
            :style="{
              background: userinfo.extractSingleMax == 0 ? 'gray' : '#ED931E',
            }"
          >
            {{ lang.v.Withdraw }}
          </div>
        </div>
      </div>
    </div>
    <!-- 操作日志 -->
    <div class="record">
      <div class="top">
        <div class="top_left">
          <div class="reward_logo">
            <img src="@/assets/img/withdrawal.png" alt="" />
          </div>
          <div class="reward_title">{{ lang.v.Wallet2 }}</div>
        </div>
        <div class="refresh" @click="refresh">
          <img :style="imageStyle" src="@/assets/img/refresh-org.png" alt="" />
        </div>
      </div>
      <div class="lists">
        <div class="list_head" v-if="lists.length == 0 ? false : true">
          <div class="head">{{ lang.v.Variable }}</div>
          <div class="head">{{ lang.v.time }}</div>
          <div class="head">{{ lang.v.type }}</div>
          <div class="head">{{ lang.v.State }}</div>
          <div class="head">{{ lang.v.Reason }}</div>
          <div class="head">{{ lang.v.OrderID }}</div>
        </div>
        <div class="list_main" v-for="list in lists" :key="list.id">
          <div
            class="head"
            :innerHTML="list.type == 4 ? list.amount : list.amount"
          ></div>
          <div class="head">{{ list.time }}</div>
          <div class="head">{{ list.typeStr }}</div>
          <div class="head">{{ list.statusStr }}</div>
          <div class="head">{{ list?.reason || lang.v.Kong }}</div>
          <div class="head">{{ list.recordNo }}</div>
        </div>
        <div class="no_text" v-if="no_more">{{ lang.v.none }}</div>
        <van-loading size="0.24rem" v-if="loading">{{
          lang.v.load
        }}</van-loading>
        <div class="list_more" v-if="more" @click="loadMore">
          <div class="text">{{ lang.v.loadmore }}</div>
          <div class="more_logo">
            <img src="~@/assets/img/down-org.png" alt="" />
          </div>
        </div>
      </div>
    </div>
    <van-overlay :show="show" :lock-scroll="false">
      <div class="wrapper" @click.stop>
        <div class="block">
          <div class="block_top">
            <div class="block_top_left">
              <div>{{ lang.v.Balance3 }}</div>
            </div>
            <div class="block_top_right" @click="right">
              <img src="@/assets/img/hidden.png" alt="" />
            </div>
          </div>
          <div class="modole">
            <div class="stick"></div>
            <div class="title">{{ lang.v.information }}</div>
          </div>
          <div style="margin-top: 0.14rem">
            <el-form
              ref="ruleFormRef"
              :label-position="labelPosition"
              label-width="0rem"
              :model="userInf"
              :rules="rules"
            >
              <el-form-item label="" class="user" prop="amount">
                <div class="inputxt">
                  <el-input-number
                    :step="1"
                    :min="userinfo.extractSingleMin"
                    :max="
                      userinfo.balance > userinfo.extractSingleMin
                        ? userinfo.extractSingleMax > userinfo.balance
                          ? userinfo.balance
                          : userinfo.extractSingleMax
                        : userinfo.extractSingleMax
                    "
                    :step-strictly="true"
                    :placeholder="Amount4"
                    class="context userId"
                    v-model="userInf.amount"
                  >
                  </el-input-number>
                  <div
                    style="
                      position: absolute;
                      color: #858c9f;
                      right: 0.28rem;
                      top: 0.05rem;
                    "
                  >
                    {{ lang.v.Balance4 }} ${{ userinfo.balance }}
                  </div>
                </div>
              </el-form-item>
              <div
                v-if="extractType == 2"
                style="margin: 0 0.2rem 0.1rem; color: #858c9f"
              >
                {{ lang.v.USDTAmout.replace('$slot', usdtamount) }}
              </div>
              <div style="margin: 0 0.2rem">
                <el-tabs v-model="activeName" class="tabs">
                  <el-tab-pane label="BANK" name="bank">
                    <div class="bank">
                      <template v-if="extractType == 1">
                        <el-form-item
                          label=""
                          class="user checkCode"
                          :required="item.required"
                          :prop="item.key"
                          v-for="item in fields"
                          :key="item.key"
                        >
                          <div class="inputxt" style="width: 100%">
                            <el-select
                              v-if="item.options"
                              v-model="userInf[item.key]"
                              :placeholder="item.label"
                            >
                              <el-option
                                v-for="option in item.options"
                                :value="option.value"
                                :key="option.value"
                                :label="option.label"
                              />
                            </el-select>
                            <el-input
                              v-else
                              :placeholder="item.label"
                              v-model="userInf[item.key]"
                            />
                          </div>
                        </el-form-item>
                      </template>
                    </div>
                  </el-tab-pane>
                  <el-tab-pane label="USDT" name="usdt">
                    <template v-if="extractType == 2">
                      <el-form-item
                        class="user checkCode"
                        :required="item.required"
                        :prop="item.key"
                        v-for="item in Usdtfields"
                        :key="item.key"
                      >
                        <div class="inputxt" style="width: 100%">
                          <el-input
                            :placeholder="item.label"
                            v-model="userInf[item.key]"
                          />
                        </div>
                      </el-form-item>
                    </template>
                  </el-tab-pane>
                </el-tabs>
              </div>
              <el-form-item label="" class="user checkCode" prop="captcha">
                <div class="inputxt">
                  <el-input
                    :placeholder="lang.v.enterCode"
                    v-model="userInf.captcha"
                  />
                  <div class="checktext">
                    <p @click="getCheckCode()" :disabled="counting">
                      {{ text }}
                    </p>
                  </div>
                </div>
              </el-form-item>
            </el-form>
          </div>
          <div class="modole">
            <div class="stick"></div>
            <div class="title">{{ lang.v.caresure }}</div>
          </div>
          <div class="main_info" v-html="notice"></div>
          <div
            class="admit"
            :style="{
              background: boxBackground,
              cursor: isInputValid ? 'pointer' : 'default',
            }"
            @click="admit(ruleFormRef)"
          >
            {{ lang.v.submitItem }}
          </div>
        </div>
      </div>
    </van-overlay>
    <!-- 转账弹窗 -->
    <van-overlay :show="transfer">
      <div class="wrapper">
        <div class="block">
          <div class="block_top">
            <div class="block_top_left">
              <div>{{ lang.v.Balance5 }}</div>
            </div>
            <div class="block_top_right" @click="TransferRight">
              <img src="@/assets/img/hidden.png" alt="" />
            </div>
          </div>
          <div class="modole">
            <div class="stick"></div>
            <div class="title">{{ lang.v.information }}</div>
          </div>
          <div style="margin-top: 0.14rem">
            <el-form
              ref="TransferRuleFormRef"
              :label-position="labelPosition"
              label-width="0rem"
              :model="userInfoTransfer"
              :rules="rulesTransfer"
            >
              <el-form-item label="" class="user" prop="amount">
                <div class="inputxt">
                  <el-input-number
                    :step="1"
                    :min="userinfo.extractSingleMin"
                    :max="
                      userinfo.balance > userinfo.extractSingleMin
                        ? userinfo.extractSingleMax > userinfo.balance
                          ? userinfo.balance
                          : userinfo.extractSingleMax
                        : userinfo.extractSingleMax
                    "
                    :step-strictly="true"
                    :placeholder="lang.v.Balance5"
                    class="context userId"
                    v-model="userInfoTransfer.amount"
                  >
                  </el-input-number>
                  <div
                    style="
                      position: absolute;
                      color: #858c9f;
                      right: 0.28rem;
                      top: 0.05rem;
                    "
                  >
                    {{ lang.v.Balance }}: ${{ userinfo.balance }}
                  </div>
                </div>
              </el-form-item>
              <el-form-item label="" class="user checkCode" prop="userId">
                <div class="inputxt">
                  <el-input
                    placeholder="Transfer user ID"
                    v-model="userInfoTransfer.userId"
                  />
                </div>
              </el-form-item>
              <el-form-item label="" class="user checkCode" prop="userIdNo">
                <div class="inputxt">
                  <el-input
                    placeholder="re-enter Transfer user ID"
                    v-model="userInfoTransfer.userIdNo"
                  />
                </div>
              </el-form-item>
              <el-form-item label="" class="user checkCode" prop="captcha">
                <div class="inputxt">
                  <el-input
                    placeholder="Verification code"
                    v-model="userInfoTransfer.captcha"
                  />
                  <div class="checktext">
                    <p @click="getCheckCode()">
                      {{ text }}
                    </p>
                  </div>
                </div>
              </el-form-item>
            </el-form>
          </div>
          <div class="modole">
            <div class="stick"></div>
            <div class="title">{{ lang.v.Precautions }}</div>
          </div>
          <div class="main_info" v-html="notice"></div>
          <div
            class="admit"
            :style="{
              background: boxBackground,
              cursor: isInputValid ? 'pointer' : 'default',
            }"
            @click="admitTransfer(TransferRuleFormRef)"
          >
            {{ lang.v.submitItem }}
          </div>
        </div>
      </div>
    </van-overlay>
    <Tabbar />
  </div>
</template>
<script setup>
import { onMounted, reactive, watch, ref, computed } from 'vue';
import Tabbar from '@/components/Tabbar/tabbar.vue';
import Top from '@/components/Top/top.vue';
import {
  wallet,
  fetchWithdrawalFields,
  fetchUSDTFields,
  extract,
  UstdTransferInfo,
  personal,
  tixian_code,
  transferApi,
} from '@/api/fetcher';
import fitty from 'fitty';
import message from '@/utils/message';
import { lang } from '@/lang';
import { savePayObject, getObject } from '@/utils/saveToLocal';
const member = ref(false);
const userinfo = ref([]);
const userInf = ref({
  amount: null,
  captcha: '',
});

// tab切换
const activeName = ref('usdt');

watch(
  userInf,
  (newValue, oldValue) => {
    if (newValue.captcha !== oldValue.captcha) {
      // 说明是在变更验证码
      return;
    }
    const { captcha, ...rest } = newValue;
    savePayObject('drawInfo', rest);
  },
  { deep: true }
);
//银行卡
const fields = ref([
  {
    key: 'bank',
    name: 'bank',
    required: 1,
    options: [
      { label: '1111', value: 1111 },
      { label: '2222', value: 2222 },
    ],
  },
  { key: 'name', name: 'name', required: 1 },
  { key: 'number', name: 'number', required: 1 },
]);
// USDT
const Usdtfields = ref([
  {
    key: 'bank',
    name: 'bank',
    required: 1,
    options: [
      { label: '1111', value: 1111 },
      { label: '2222', value: 2222 },
    ],
  },
]);

// 缩小
const walletBox = ref(null);
const walletText = ref(null);

const usdtInfor = reactive({
  exchangeRate: '',
  minPayAmount: '',
});
const usdtamount = computed(() => {
  return (
    (Number(userInf.value.amount) / Number(usdtInfor.exchangeRate)).toFixed(2) |
    0
  );
});
const no_more = ref(false);

const Amount4 = lang.v.Amount4;
let show = ref(false);
const show_tixian = async () => {
  if (userinfo.value.extractSingleMax == 0) {
    return;
  } else {
    show.value = true;
    await personal().then(res => {
      userinfo.value = res;
      window.localStorage.setItem('userInfo', JSON.stringify(res));
    });
    try {
      const userInfoString = localStorage.getItem('userInfo');
      if (userInfoString && userInfoString !== 'undefined') {
        userinfo.value = JSON.parse(userInfoString);
      }
    } catch (e) {
      console.error('Error parsing userInfo from localStorage:', e);
    }
  }
};
const notice = computed(() => {
  return lang.v.drawoutNotice
    .replace('$extractSingleMin', userinfo.value.extractSingleMin)
    .replace('$extractSingleMax', userinfo.value.extractSingleMax)
    .replace(
      '$extractChargeRate',
      parseFloat(userinfo.value.extractChargeRate.replace('%', ''))
    )
    .replace('$extractDailyCount', userinfo.value.extractDailyCount);
});
const text = ref(lang.v.getCode);
const counting = ref(false);
let tag = true;
let timer = null;
const getCheckCode = async () => {
  if (timer) return;
  if (tag) {
    let time = 90; //定义剩下的秒数
    text.value = time + 's';
    counting.value = true;
    timer = setInterval(() => {
      if (time === 0) {
        clearInterval(timer);
        text.value = lang.v.getCode;
        counting.value = false;
      } else {
        time--;
        text.value = time + 's';
      }
    }, 1000);
    await tixian_code().then(res => {
      if (res) {
        //showdilog 做提示
        message.success(res);
      } else {
        clearInterval(timer);
        text.value = lang.v.getCode;
      }
    });
  } else {
    message.warning(lang.v.enterPhoneNumber);
  }
};
const right = () => {
  show.value = false;
  userInf.value = {};
  // 清除定时器
  clearInterval(timer);
  text.value = lang.v.GetVerificationCode;
  timer = null; // 重置全局变量
};
const ruleFormRef = ref();

const validateAmount = (rule, value, callback) => {
  if (value === null) {
    callback(new Error(lang.v.WithdrawAmountTip));
  } else if (userinfo.value.balance < userinfo.value.extractSingleMin) {
    callback(new Error(lang.v.WithdrawAmountTipMin));
  } else if (!/^\d*\.?\d{0,2}$/.test(value)) {
    callback(new Error(lang.v.WithdrawAmountTipDecimal));
  } else {
    callback();
  }
};
const validatecode = (rule, value, callback) => {
  if (value === '') {
    callback(new Error(lang.v.VerificationCodeTip));
  } else {
    callback();
  }
};
const rules = reactive({
  amount: [{ validator: validateAmount, trigger: 'blur', reqired: true }],
  code: [{ validator: validatecode, trigger: 'blur', reqired: true }],
});
const extractType = computed(() => {
  if (activeName.value == 'bank') {
    return 1; //三方支付
  } else if (activeName.value == 'usdt') {
    return 2; ///USDT
  } else {
    return 0;
  }
});
// 提交
const isInputValid = computed(() => {
  const { amount, bank, bankCardNo, bankCardHolder, captcha, bankCardNoUSTD } =
    userInf.value;
  if (extractType.value == 1) {
    return (
      amount !== '' &&
      bank !== '' &&
      bankCardNo !== '' &&
      bankCardHolder !== '' &&
      captcha !== ''
    );
  } else if (extractType.value == 2) {
    return amount !== '' && bankCardNoUSTD !== '' && captcha !== '';
  }
  return '';
});
const boxBackground = computed(() => {
  return isInputValid.value ? '#ED931E' : 'gray';
});
const admit = ruleFormRef => {
  if (isInputValid.value) {
    if (
      !ruleFormRef ||
      userinfo.value.balance < userinfo.value.extractSingleMin
    ) {
      return;
    }
    ruleFormRef.validate(async valid => {
      let params;
      if (valid) {
        const entries = Object.entries(userInf.value);
        if (extractType.value == 1) {
          params = entries.reduce((ret, entry) => {
            if (entry[0] === 'amount' || entry[0] === 'captcha') {
              ret[entry[0]] = entry[1];
            } else {
              const field = fields.value.find(field => field.key === entry[0]);
              if (field) {
                if (field.extra) {
                  // 如果是额外参数，则构建到extra里面
                  if (ret.extra) {
                    ret.extra[entry[0]] = entry[1];
                  } else {
                    ret.extra = { [entry[0]]: entry[1] };
                  }
                } else {
                  ret[entry[0]] = entry[1];
                }
              }
            }
            ret.extractType = 1;
            return ret;
          }, {});
        } else if (extractType.value == 2) {
          params = entries.reduce((ret, entry) => {
            if (entry[0] === 'amount' || entry[0] === 'captcha') {
              ret[entry[0]] = entry[1];
            }
            if (entry[0] == 'bankCardNoUSDT') {
              ret.bankCardNo = entry[1];
            }

            ret.extractType = 2;
            return ret;
          }, {});
        }
        await extract(params).then(async res => {
          if (res) {
            message.success('Withdrawal successful');
            right();
            await wallet({
              page: pages.page,
              limit: pages.limit,
            }).then(res => {
              lists.value = res?.list;
            });
            await personal().then(res => {
              userinfo.value = res;
              window.localStorage.setItem('userInfo', JSON.stringify(res));
            });
          } else {
            console.log('err');
          }
        });
      }
    });
  }
};

/* 转账方法 */
const transfer = ref(false);
const TransferRuleFormRef = ref(null);
const show_transfer = async () => {
  if (userinfo.value.extractSingleMax == 0) {
    return;
  } else {
    transfer.value = true;
    /* 调用获取用户信息 */
    await personal().then(res => {
      userinfo.value = res;
      window.localStorage.setItem('userInfo', JSON.stringify(res));
    });
  }
};
/* 关闭按钮 */
const TransferRight = () => {
  transfer.value = false;
  const fieldsToClear = ['amount', 'userId', 'userIdNo', 'captcha'];
  fieldsToClear.forEach(field => {
    userInfoTransfer.value[field] = '';
  });
  if (timer) {
    // 清除定时器
    clearInterval(timer);
    text.value = lang.v.GetVerificationCode;
    timer = null; // 重置全局变量
  }
};
/* 定义值 */
const userInfoTransfer = ref({
  amount: null,
  userId: '',
  userIdNo: '',
  captcha: '',
});
/* 规则验证 */
const validateAmounts = (rule, value, callback) => {
  if (value === null || undefined) {
    callback(new Error(lang.v.TransferAmountTip));
  } else if (userinfo.value.balance < userinfo.value.extractSingleMin) {
    callback(new Error(lang.v.TransferAmountTipMin));
  } else if (!/^\d*\.?\d{0,2}$/.test(value)) {
    callback(new Error(lang.v.TransferAmountTipDecimal));
  } else {
    callback();
  }
};
const validateUserId = (rule, value, callback) => {
  if (value === '') {
    callback(new Error());
  } else {
    callback();
  }
};
const validateUserIdNo = (rule, value, callback) => {
  if (value === '') {
    callback(new Error());
  } else if (value != userInfoTransfer.value.userId) {
    callback(new Error(lang.v.TransferUserIDTips));
  } else {
    callback();
  }
};
const rulesTransfer = reactive({
  amount: [{ validator: validateAmounts, trigger: 'blur', reqired: true }],
  userId: [{ validator: validateUserId, trigger: 'blur', reqired: true }],
  userIdNo: [{ validator: validateUserIdNo, trigger: 'blur', reqired: true }],
});
/* 表单提交 */
const admitTransfer = async ruleFormRef => {
  if (
    !ruleFormRef ||
    userinfo.value.balance < userinfo.value.extractSingleMin
  ) {
    return;
  }
  await ruleFormRef.validate(async valid => {
    if (valid) {
      await transferApi({
        userId: userInfoTransfer.value.userIdNo,
        amount: userInfoTransfer.value.amount,
        captcha: userInfoTransfer.value.captcha,
      }).then(async res => {
        if (res) {
          message.success('transfer successful');
          TransferRight();
          await wallet({
            page: pages.page,
            limit: pages.limit,
          }).then(res => {
            lists.value = res?.list;
          });
          await personal().then(res => {
            userinfo.value = res;
            window.localStorage.setItem('userInfo', JSON.stringify(res));
          });
        } else {
          console.log('err');
        }
      });
    }
  });
};
const more = ref(false);
const loading = ref(false);
// 加载更多
const loadMore = async () => {
  loading.value = true;
  setTimeout(async () => {
    await wallet({
      page: pages.page + 1,
      limit: pages.limit,
    }).then(res => {
      loading.value = false;
      const data = res;
      if (data.list.length < pages.limit) {
        more.value = false;
      } else {
        pages.page++;
        more.value = true;
      }
      const temp = [];
      data.list.forEach(v => {
        temp.push(v);
      });
      lists.value = [...lists.value, ...temp];
    });
  }, 500);
};
const imageStyle = ref({
  transform: 'rotate(0deg)', // 初始旋转角度为0度
  transition: 'transform 0.5s ease', // 添加过渡效果
});
// 刷新按钮
const refresh = async () => {
  const currentRotation =
    parseInt(imageStyle.value.transform.slice(7), 10) || 0; // 获取当前旋转角度
  const rotationAngle = currentRotation + 360; // 计算下一次旋转角度
  // 更新图片的样式，应用旋转效果
  imageStyle.value.transform = `rotate(${rotationAngle}deg)`;
  lists.value = [];
  setTimeout(async () => {
    await wallet({
      page: 1,
      limit: pages.limit,
    }).then(async res => {
      const data = res;
      if (data.list.length >= pages.limit) {
        more.value = true;
        no_more.value = false;
      } else {
        more.value = false;
        if (data.length == 0) {
          no_more.value = true;
        }
      }
      lists.value = [...data.list];
    });
  }, 500);
};
const lists = ref([]);
const pages = reactive({
  page: 1,
  limit: 6,
});
onMounted(async () => {
  userInf.value = getObject('drawInfo');
  await personal().then(res => {
    userinfo.value = res;
    window.localStorage.setItem('userInfo', JSON.stringify(res));
  });
  try {
    const userInfoString = localStorage.getItem('userInfo');
    if (userInfoString && userInfoString !== 'undefined') {
      userinfo.value = JSON.parse(userInfoString);
    }
  } catch (e) {
    console.error('Error parsing userInfo from localStorage:', e);
  }
  if (userinfo.value?.talentCardType != 0) {
    member.value = true;
  }
  await wallet({
    page: pages.page,
    limit: pages.limit,
  }).then(res => {
    lists.value = res?.list;
    if (lists.value.length >= pages.limit) {
      more.value = true;
      no_more.value = false;
    } else {
      more.value = false;
      if (lists.value.length == 0) {
        no_more.value = true;
      }
    }
  });
  await fetchWithdrawalFields().then(resp => {
    fields.value = resp.map(item => {
      const pattern = item.exp ? new RegExp(item.exp) : undefined;
      rules[item.key] = {
        pattern,
        message: item.message,
        required: !!item.required,
      };
      return {
        ...item,
        label: item.name || `server_${item.key}`,
        options: item.options?.map(option => {
          const opt = option.split(':');
          return {
            label: opt[1],
            value: opt[0],
          };
        }),
      };
    });
  });
  await fetchUSDTFields().then(resp => {
    Usdtfields.value = resp.map(item => {
      const pattern = item.exp ? new RegExp(item.exp) : undefined;
      rules[item.key + 'USDT'] = {
        pattern,
        message: item.message,
        required: !!item.required,
      };
      return {
        ...item,
        key: item.key + 'USDT',
        label: item.name || `server_${item.key}`,
      };
    });
  });
  await UstdTransferInfo().then(async res => {
    Object.assign(usdtInfor, res);
  });
  if (walletBox.value && walletText.value) {
    fitty(walletText.value, {
      minSize: 8,
      maxSize: 32,
      container: walletBox.value,
    });
  }
});
</script>
<style lang="less" scoped>
:deep(.el-form-item__error) {
  margin-left: 0.24rem;
}

:deep(.van-loading) {
  text-align: center;
}

.nav {
  width: 100%;
  height: 0.2rem;
  display: flex;
  margin-top: 0.16rem;
  justify-content: space-between;

  .nav_left {
    display: flex;
    height: 0.2rem;
    font-size: 0.14rem;
    font-family: PingFang SC, PingFang SC;
    font-weight: 400;
    color: #444444;
    line-height: 0.2rem;
    margin-left: 0.14rem;

    .left_jian {
      width: 0.2rem;
      height: 0.2rem;

      img {
        width: 100%;
      }
    }

    .word {
      background: linear-gradient(90deg, #f46b45 0%, #eea849 100%);
      -webkit-background-clip: text;
      -webkit-text-fill-color: transparent;
    }
  }
}

:deep(.el-input-number) {
  width: 100%;
  height: 0.46rem;
  border-radius: 0.06rem;

  .el-input-number__decrease {
    display: none;
  }

  .el-input-number__increase {
    display: none;
  }

  .el-input {
    border: none !important;
    box-shadow: none !important;
    width: 100%;
    height: 0.46rem;

    .el-input__wrapper {
      padding: 0rem;
      width: 100%;
      height: 0.46rem;
      display: flex;
      justify-content: left;
      border: none !important;
      background-color: #ffffff;

      .el-input__inner {
        display: flex;
        border: none !important;
        box-shadow: none !important;
        justify-content: left;
        text-align: left;
        padding-left: 0.1rem;
      }
    }
  }
}

.wallet {
  width: 92.53%;
  height: 1.38rem;
  margin: auto;
  margin-top: 0.16rem;
  background: linear-gradient(180deg, #fff9ec 0%, #ffffff 72%);
  border-radius: 0.06rem;

  .top_wallet {
    width: 91.93%;
    height: 0.685rem;
    margin: auto;
    border-bottom: 0.01rem solid rgba(133, 140, 159, 0.2);
    display: flex;
    justify-content: space-between;

    .top_left {
      width: 1.2874rem;
      height: 0.41rem;
      background: url(~@/assets/img/wallet_left.png) no-repeat;
      background-size: 100% 100%;
      margin-top: 0.14rem;

      .pictrue {
        width: 0.4rem;
        height: 0.4rem;
        border-radius: 50%;
        margin: auto;

        img {
          width: 100%;
          height: 100%;
          border-radius: 50%;
        }
      }
    }

    .top_right {
      text-align: right;
      height: 0.41rem;
      margin-top: 0.14rem;

      .info_name {
        height: 0.2rem;
        font-size: 0.14rem;
        font-family: PingFang SC, PingFang SC;
        font-weight: 500;
        color: #444444;
        line-height: 0.2rem;
      }

      .info_id {
        height: 0.2rem;
        font-size: 0.12rem;
        font-family: PingFang SC, PingFang SC;
        font-weight: 400;
        color: #858c9f;
        line-height: 0.2rem;
      }
    }
  }

  .main_wallet {
    width: 91.93%;
    display: flex;
    margin: auto;
    justify-content: space-between;
    margin-top: 0.16rem;
    .main_left {
      font-size: 0.16rem;
      font-family: MiSans, MiSans;
      font-weight: 600;
      color: #444444;
      line-height: 0.21rem;
      margin-top: -0.04rem;
      max-width: 1.7rem;
      overflow: hidden;
      display: flex;
      align-items: center;
      .wallet_balance {
        width: 1.2rem;
        height: 0.42rem;
        font-size: 0.32rem;
        font-family: MiSans, MiSans;
        font-weight: 600;
        color: #444444;
        line-height: 0.42rem;
      }
    }
    .main_right {
      display: flex;
      justify-content: space-between;
      opacity: 1;
      font-size: 0.14rem;
      font-family: PingFang SC, PingFang SC;
      font-weight: 500;
      color: #ffffff;
      .transfer {
        width: 0.96rem;
        height: 0.34rem;
        background: #ed931e;
        border-radius: 0.61rem;
        line-height: 0.34rem;
        text-align: center;
        margin-right: 0.3rem;
      }
      .withdrawal {
        width: 0.96rem;
        height: 0.34rem;
        background: #ed931e;
        border-radius: 0.61rem;
        line-height: 0.34rem;
        text-align: center;
      }
    }
  }
}

.record {
  width: 92.53%;
  margin: auto;
  margin-top: 0.16rem;
  background: #ffffff;
  border-radius: 0.06rem;
  padding-bottom: 0.06rem;
  .top {
    width: 100%;
    height: 0.2rem;
    display: flex;
    padding-top: 0.14rem;
    justify-content: space-between;

    .top_left {
      display: flex;
      margin-left: 0.14rem;

      .reward_logo {
        width: 0.2rem;
        height: 0.2rem;

        img {
          width: 100%;
          height: 100%;
        }
      }

      .reward_title {
        height: 0.2rem;
        font-size: 0.16rem;
        font-family: PingFang SC, PingFang SC;
        font-weight: 500;
        color: rgba(68, 68, 68, 1);
        line-height: 0.2rem;
        margin-left: 0.06rem;
      }
    }

    .refresh {
      width: 0.2rem;
      height: 0.2rem;
      margin-right: 0.14rem;

      img {
        width: 100%;
        height: 100%;
      }
    }
  }

  .lists {
    height: 5rem;
    width: 100%;
    overflow-x: auto;
    position: relative;

    .list_head {
      width: 92.5%;
      display: flex;
      height: 0.5rem;
      margin: auto;

      .head {
        width: 1.5rem;
        // height: 1.5rem;
        line-height: 0.5rem;
        font-size: 0.14rem;
        font-family: PingFang SC, PingFang SC;
        font-weight: 400;
        color: #858c9f;
        flex-shrink: 0;
        border-bottom: 1px solid rgba(133, 140, 159, 0.2);
      }

      .head:nth-child(2) {
        width: 1.4rem;
      }

      .head:nth-child(3) {
        width: 1.5rem;
      }

      .head:nth-child(5) {
        width: 2.1rem;
      }
    }

    .list_main {
      width: 92.5%;
      display: flex;
      margin: auto;
      .head {
        width: 1.5rem;
        line-height: 0.4rem;
        font-size: 0.14rem;
        font-family: PingFang SC, PingFang SC;
        font-weight: 400;
        color: #858c9f;
        flex-shrink: 0;
        overflow: hidden;
        word-wrap: break-word;
      }

      .head:nth-child(2) {
        width: 1.4rem;
      }

      .head:nth-child(3) {
        width: 1.5rem;
      }

      .head:nth-child(5) {
        width: 2.1rem;
      }
    }

    .no_text {
      text-align: center;
      height: 0.2rem;
      font-size: 0.14rem;
      font-family: PingFang SC, PingFang SC;
      font-weight: 500;
      color: #858c9f;
      line-height: 0.2rem;
      margin-top: 0.06rem;
    }

    .list_more {
      width: 100%;
      display: flex;
      justify-content: center;
      margin-top: 0.06rem;

      .text {
        height: 0.2rem;
        font-size: 0.14rem;
        font-family: PingFang SC, PingFang SC;
        font-weight: 500;
        color: #444444;
        line-height: 0.2rem;
      }

      .more_logo {
        width: 0.2rem;
        height: 0.2rem;

        img {
          width: 100%;
          height: 100%;
        }
      }
    }
  }
}

.wrapper {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
}

.block {
  width: 92.53%;
  background-color: #fff;
  border-radius: 0.06rem;
  padding-bottom: 0.14rem;

  .block_top {
    display: flex;
    justify-content: space-between;
    padding-top: 0.14rem;

    .block_top_left {
      display: flex;
      height: 0.4rem;
      line-height: 0.4rem;
      margin-left: 0.14rem;
      font-size: 0.14rem;
      font-family: PingFang SC, PingFang SC;
      font-weight: 500;
      color: #444444;
    }

    .block_top_right {
      width: 0.4rem;
      height: 0.4rem;
      margin-right: 0.06rem;

      img {
        width: 100%;
        height: 100%;
      }
    }
  }

  .modole {
    display: flex;
    margin-left: 0.14rem;

    .stick {
      margin-top: 0.06rem;
      width: 0.02rem;
      height: 0.1rem;
      background: #ff5f5b;
      border-radius: 0.06rem;
      opacity: 1;
    }

    .title {
      height: 0.22rem;
      font-size: 0.16rem;
      font-family: PingFang SC, PingFang SC;
      font-weight: 500;
      color: #59390f;
      line-height: 0.22rem;
      margin-left: 0.06rem;
    }
  }
  .swith {
    margin-bottom: 0.1rem;
    display: flex;
    align-items: center;
  }
  :deep(.el-form) {
    width: 100%;

    .user {
      .el-form-item__content {
        width: 100%;

        .inputxt {
          width: 92.5%;
          margin: auto;
          display: flex;

          .checktext {
            font-size: 0.14rem;
            position: absolute;
            right: 0.3rem;
            top: 0.15rem;

            p {
              line-height: 0.2rem;
              color: #ed931e;
            }
          }

          .el-input {
            width: 100%;
            height: 0.46rem;
            line-height: 0.5rem;
            border-radius: 0.61rem;

            .el-input__wrapper {
              width: 100%;
              height: 0.46rem;
              line-height: 0.5rem;
              border-radius: 0.61rem;

              .input__inner {
                width: 70%;
                height: 0.46rem;
                line-height: 0.5rem;
              }
            }
          }
        }

        .el-form-item__error {
          font-size: 0.14rem;
          // top: 80%;
        }
      }
    }

    .checkCode {
      .el-form-item__content {
        .el-form-item__error {
          font-size: 0.14rem !important;
        }

        .inputxt {
          .el-input {
            .el-input__wrapper {
              .el-input__inner {
                font-size: 0.14rem;
              }
            }
          }
        }
      }
    }

    .el-form-item {
      margin-bottom: 0.2rem;
    }
  }

  .main_info {
    width: 92.5%;
    margin: auto;
    margin-top: 0.14rem;
    height: 2rem;
    overflow-y: scroll;
    p {
      margin: 0;
      font-size: 0.14rem;
      font-family: PingFang SC, PingFang SC;
      font-weight: 400;
      color: #858c9f;
      line-height: 0.2rem;
    }
  }

  .admit {
    width: 0.96rem;
    height: 0.34rem;
    background: #ed931e;
    border-radius: 0.61rem;
    opacity: 1;
    text-align: center;
    line-height: 0.34rem;
    font-size: 0.14rem;
    font-family: PingFang SC, PingFang SC;
    font-weight: 500;
    color: #ffffff;
    float: right;
    margin-top: 0.12rem;
    margin-right: 0.14rem;
  }
  :deep(.el-switch__label.is-active) {
    color: #ff5f5b;
  }
}
</style>
